<template>
  <div v-if="item" class="page-content">
    <page-breadcrumb
      title="Landing Page Detail"
      class="mb-2"
      :items="breadcrumbItems"
    />
    <h2><b>Landing Page</b></h2>
    <form-generator :model="item" :schema="item_form_schema" />
    <b-card v-if="item.layout === 1">
      <h4>Layers</h4>
      <app-collapse
        v-if="item.content.layers.length"
        type="shadow"
        class="my-2 border p-1"
      >
        <app-collapse-item
          v-for="(layer, index) in item.content.layers"
          :key="layer.id || layer._id"
          :title="`${layer.store_id || `Layer ${index + 1}`}`"
        >
          <template slot="header">
            <h4>{{ layer.store_id || `Layer ${index + 1}` }}</h4>
            <div style="position: absolute; right: 30px; ">
              <b-button
                variant="flat-danger"
                class="btn-icon rounded-circle"
                @click.stop="removeItem(index)"
              >
                <feather-icon icon="Trash2Icon" />
              </b-button>
            </div>
          </template>
          <form-generator :model="layer" :schema="layer_form_schema" />
        </app-collapse-item>
      </app-collapse>
      <div class="text-center">
        <b-button variant="success" @click="addLayer">+ Add Layer</b-button>
      </div>
    </b-card>
    <b-card v-if="item.layout === 2">
      <h4>Products</h4>
      <app-collapse
        v-if="item.content.products.length"
        type="shadow"
        class="my-2 border p-1"
      >
        <app-collapse-item
          v-for="(product, index) in item.content.products"
          :key="product.id || product._id"
          :title="`${layer.store_id || `Layer ${index + 1}`}`"
        >
          <template slot="header">
            <h4>{{ layer.store_id || `Layer ${index + 1}` }}</h4>
            <div style="position: absolute; right: 30px; ">
              <b-button
                variant="flat-danger"
                class="btn-icon rounded-circle"
                @click.stop="removeItem(index)"
              >
                <feather-icon icon="Trash2Icon" />
              </b-button>
            </div>
          </template>
          <form-generator :model="layer" :schema="layer_form_schema" />
        </app-collapse-item>
      </app-collapse>
      <div class="text-center">
        <b-button variant="success" @click="addLayer">+ Add Layer</b-button>
      </div>
    </b-card>
    <action-buttons
      update-button
      back-button
      delete-button
      @update="update"
      :is-updating="isUpdating"
      @delete="deleteItem"
      :is-deleting="isDeleting"
    />
  </div>
</template>
<script>
import service from "../service";
import productDefault from "../productDefaultObject";
const item_form_schema = [
  {
    cols: 12,
    fields: [
      {
        label: "Layout",
        field: "layout",
        input_type: "select",
        options: [
          { text: "Layout 1", value: 1 },
          { text: "Layout 2", value: 2 },
          // { text: "Layout 3", value: 3 },
          // { text: "Layout 4", value: 4 },
          // { text: "Layout 5", value: 5 },
          // { text: "Layout 6", value: 6 },
          // { text: "Layout 7", value: 7 }
        ],
        validate: { required: true }
      },
      { label: "Landing page key", field: "key", validate: { required: true } },
      { label: "Title", field: "title", input_type: "ui-component" },
      { label: "Landing page background", field: "background" },
    ]
  }
];
const layout_1_form_schema = [
  {
    cols: 12,
    fields: [
      { label: 'Type', field: 'type', required: true },
      { label: 'Asset bundle', field: 'asset_bundle' },
      { label: 'Store ID', field: 'store_id' },
    ]
  }
];
const layout_2_form_schema = [
  {
    cols: 4,
    fields: [
      { label: 'Backgound', field: 'background', input_type: 'image' },
    ]
  },
  {
    cols: 8,
    fields: [
      { label: 'Subtitle', field: 'subtitle' },
      { label: 'Asset bundle', field: 'asset_bundle' },
      { label: 'Store ID', field: 'store_id' },
    ]
  }
]
const layer_schema = [

]
const product_schema =[
  {
    cols: 4,
    fields: [
      { label: 'Image', field: 'image', input_type: 'image' },
    ]
  },
  {
    cols: 8,
    fields: [
      { label: 'Quantity', field: 'quantity', input_type: 'number' },
      { label: 'Price', field: 'price' },
      { label: 'Discount Info', field: 'discount_info' },
    ]
  }
]
export default {
  components: {},
  data() {
    return {
      item: null,
      item_form_schema,
      isUpdating: false,
      isDeleting: false,
      layer_form_schema,
      productDefault
    };
  },
  computed: {
    itemId() {
      return this.$route.params.id;
    },
    breadcrumbItems() {
      let items = [
        {
          text: "Landing Page",
          to: { name: "landing-page-list" }
        },
        {
          text: `${this.item ? this.item.title : this.itemId}`,
          active: true
        }
      ];
      return items;
    },
    authLanguage() {
      return this.$store.getters["auth/language"];
    }
  },
  created() {
    this.getDetail();
  },
  methods: {
    async getDetail() {
      this.item = await service.getDetail({ id: this.itemId });
    },
    async update() {
      this.isUpdating = true;
      await service.update({
        data: JSON.stringify(this.item)
      });
      this.getDetail();
      this.isUpdating = false;
    },
    async deleteItem() {
      await service.delete({ id: this.itemId });
      this.$router.push({ name: "landing-page-list" });
    },
    addLayer() {
      this.item.layers.push({
        type: null,
        asset_bundle: null,
        store_id: null
      });
    },
    removeItem(index) {
      this.item.layers.splice(index, 1);
    }
  }
};
</script>
